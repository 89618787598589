// MARK: - Distances

/// <reference types="@types/google.maps" />

export function calculateDistance(a: google.maps.LatLng, b: google.maps.LatLng, unit: 'kilometers' | 'miles') {
  const radii = {
    miles: 3958.8,
    kilometers: 6371.071
  }

  const earthRadiusInMiles = radii[unit]
  if (earthRadiusInMiles == null) throw new Error(`Unit "${unit}" is not supported`)

  const aLatitudeRadians = a.lat() * (Math.PI / 180)
  const bLatitudeRadians = b.lat() * (Math.PI / 180)
  const latitudeDifference = bLatitudeRadians - aLatitudeRadians
  const longitudeDifference = (b.lng() - a.lng()) * (Math.PI / 180)

  return (
    2 *
    earthRadiusInMiles *
    Math.asin(
      Math.sqrt(
        Math.sin(latitudeDifference / 2) * Math.sin(latitudeDifference / 2) +
          Math.cos(aLatitudeRadians) *
            Math.cos(bLatitudeRadians) *
            Math.sin(longitudeDifference / 2) *
            Math.sin(longitudeDifference / 2)
      )
    )
  )
}

export function formatDistance(distance: number | null, unit: 'kilometers' | 'miles') {
  if (distance == null) return ''

  const digits = distance.toFixed()

  if (unit === 'kilometers') {
    return `${digits} km`
  } else if (unit === 'miles') {
    return digits === '1' ? '1 mile' : `${digits} miles`
  } else {
    return ''
  }
}
