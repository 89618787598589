//
// Footer Menu Controller
//

import { computeSize, onLoaded } from '../utilities'

export default class FooterMenuController {
  public static readonly shared = new FooterMenuController()

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(this.addEventListeners.bind(this))
  }

  // MARK: - Event Listeners

  private addEventListeners() {
    document.addEventListener('click', (event) => {
      if (window.innerWidth >= 992) return
      if (event.target instanceof HTMLElement === false) return
      if (event.target.matches('.footer .footer__menu .menu-item-has-children > a') === false) return

      event.preventDefault()
      event.stopImmediatePropagation()
      this.toggleMenuItem(event.target)
    })
  }

  // MARK: - Menu Items

  private isMenuItemOpen(menuItemElement: HTMLElement): boolean {
    return menuItemElement.classList.contains('menu-item--open')
  }

  private openMenuItem(menuItemElement: HTMLElement) {
    if (menuItemElement.classList.contains('menu-item--open')) return

    const subMenuElements = menuItemElement.querySelectorAll(':scope > .sub-menu')
    if (subMenuElements.length < 1) return

    const subMenuElement = subMenuElements[0] as HTMLElement
    const { width, height } = computeSize(subMenuElement)

    requestAnimationFrame(() => {
      subMenuElement.style.setProperty('width', width)
      subMenuElement.style.setProperty('height', '0')
      subMenuElement.style.setProperty('overflow', 'hidden')
      menuItemElement.classList.add('menu-item--open')

      requestAnimationFrame(() => {
        const handler = (event: TransitionEvent) => {
          const element = event.target
          if (element instanceof HTMLElement === false) return
          if (element.parentElement !== menuItemElement) return
          if (element.classList.contains('sub-menu') === false) return

          subMenuElement.style.removeProperty('width')
          subMenuElement.style.removeProperty('height')
          subMenuElement.style.removeProperty('overflow')
          subMenuElement.removeEventListener('transitionend', handler)
        }

        subMenuElement.addEventListener('transitionend', handler)
        subMenuElement.style.setProperty('height', height)
      })
    })
  }

  private closeMenuItem(menuItemElement: HTMLElement) {
    if (menuItemElement.classList.contains('menu-item--open') === false) return

    const subMenuElements = menuItemElement.querySelectorAll(':scope > .sub-menu')
    if (subMenuElements.length < 1) return

    const subMenuElement = subMenuElements[0] as HTMLElement
    const { width, height } = computeSize(subMenuElement)

    requestAnimationFrame(() => {
      subMenuElement.style.setProperty('width', width)
      subMenuElement.style.setProperty('height', height)
      subMenuElement.style.setProperty('overflow', 'hidden')
      menuItemElement.classList.remove('menu-item--open')

      requestAnimationFrame(() => {
        const handler = (event: TransitionEvent) => {
          const element = event.target
          if (element instanceof HTMLElement === false) return
          if (element.parentElement !== menuItemElement) return
          if (element.classList.contains('sub-menu') === false) return

          subMenuElement.style.removeProperty('width')
          subMenuElement.style.removeProperty('height')
          subMenuElement.style.removeProperty('overflow')
          subMenuElement.removeEventListener('transitionend', handler)
        }

        subMenuElement.addEventListener('transitionend', handler)
        subMenuElement.style.setProperty('height', '0')
      })
    })
  }

  private toggleMenuItem(referenceElement: Element) {
    const menuItemElement = referenceElement.closest('.menu-item.menu-item-has-children')
    if (menuItemElement instanceof HTMLElement === false) return

    if (this.isMenuItemOpen(menuItemElement)) {
      this.closeMenuItem(menuItemElement)
    } else {
      this.openMenuItem(menuItemElement)
    }
  }
}
