//
// Map Controller
//

/// <reference types="@types/google.maps" />

import { onLoaded } from '../utilities'

export default class MapController {
  public static readonly controllers = new Map<String, MapController>()

  private element: HTMLElement
  private infoWindow: google.maps.InfoWindow | null
  private map: google.maps.Map | null
  private marker: google.maps.marker.AdvancedMarkerElement | null

  // MARK: - Set Up

  public static install() {
    onLoaded(this.initializeControllers.bind(this))
  }

  public static initializeControllers() {
    const elements = Array.from(document.querySelectorAll('[data-map]'))
    for (const element of elements) {
      if (element instanceof HTMLElement === false) continue

      const identifier = element.getAttribute('data-map')
      if (typeof identifier !== 'string' || identifier.length < 1) continue
      if (this.controllers.has(identifier)) continue

      const controller = new MapController(element)
      this.controllers.set(identifier, controller)
    }
  }

  // MARK: - Google Maps API

  private static async importLibraries() {
    const mapsAPI = await google.maps.importLibrary('maps')
    const markerAPI = await google.maps.importLibrary('marker')

    return {
      ...(mapsAPI as google.maps.MapsLibrary),
      ...(markerAPI as google.maps.MarkerLibrary)
    }
  }

  // MARK: - Object Lifecycle

  public constructor(element: HTMLElement) {
    this.element = element
    this.infoWindow = null
    this.map = null
    this.marker = null
    this.initializeMap()
  }

  // MARK: - Getters

  public get id() {
    return this.element.getAttribute('data-map-id') || undefined
  }

  public get markerTitle() {
    return this.element.getAttribute('data-map-marker-title') || undefined
  }

  public get latitude() {
    const latitudeString = this.element.getAttribute('data-map-latitude')
    if (latitudeString == null) return undefined

    const latitude = parseFloat(latitudeString)
    return isNaN(latitude) ? undefined : latitude
  }

  public get longitude() {
    const longitudeString = this.element.getAttribute('data-map-longitude')
    if (longitudeString == null) return undefined

    const longitude = parseFloat(longitudeString)
    return isNaN(longitude) ? undefined : longitude
  }

  public get zoom() {
    const zoomString = this.element.getAttribute('data-map-zoom')
    if (zoomString == null) return undefined

    const zoom = parseFloat(zoomString)
    return isNaN(zoom) ? undefined : zoom
  }

  // MARK: - Map Initialization

  private async initializeMap() {
    if (window.google == null) {
      this.element.setAttribute('hidden', 'hidden')
      return
    }

    if (this.map || this.marker || this.latitude == null || this.longitude == null || this.zoom == null) return

    const { Map, InfoWindow, AdvancedMarkerElement, PinElement } = await MapController.importLibraries()

    this.map = new Map(this.element, {
      mapId: this.id,
      center: { lat: this.latitude, lng: this.longitude },
      zoom: this.zoom
    })

    if (this.id == null) return

    const pinElement = new PinElement({
      background: '#887967',
      borderColor: '#5f5549',
      glyphColor: '#ffffff',
      scale: 1.25
    })

    this.infoWindow = new InfoWindow()

    this.marker = new AdvancedMarkerElement({
      map: this.map,
      position: { lat: this.latitude, lng: this.longitude },
      content: pinElement.element,
      title: this.markerTitle
    })

    this.marker.addListener('click', () => {
      const infoWindow = this.infoWindow
      const marker = this.marker
      if (infoWindow == null || marker == null) return

      infoWindow.close()
      if (typeof marker.title !== 'string' || marker.title.length < 1) return

      infoWindow.setContent(marker.title)
      infoWindow.open(marker.map, marker)
    })
  }
}

MapController.install()
