//
// Cart Form Toolbar Controller
//

import SiteNavigationController from './site_navigation_controller'
import { onLoaded, onResize, onScroll } from '../utilities'

export default class CartFormToolbarController {
  public static readonly shared = new CartFormToolbarController()

  private containerElement: HTMLElement | null = null
  private formElement: HTMLElement | null = null

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(this.prepareCartForm.bind(this))
  }

  // MARK: - Setup

  public prepareCartForm() {
    this.containerElement = document.querySelector('.woocommerce div.product.product-type-simple .cart-form-toolbar')
    if (this.containerElement instanceof HTMLElement === false) return

    this.formElement = this.containerElement.querySelector('form.cart')
    this.updateVisibility()

    onResize(this.updateVisibility.bind(this))
    onScroll(this.updateVisibility.bind(this))
    document.addEventListener('site-nav:updated', this.updateFixedPosition.bind(this))
  }

  // MARK: - Helpers

  private calculateSiteNavigationBottom(): number {
    let top = 0

    const adminBarElement = SiteNavigationController.shared.getAdminBarElement()
    if (adminBarElement) {
      const { height, position } = getComputedStyle(adminBarElement)
      if (position === 'fixed') top += parseFloat(height)
    }

    if (SiteNavigationController.shared.isSticky()) {
      const siteNavigationElement = SiteNavigationController.shared.getElement()
      if (siteNavigationElement) {
        const siteNavigationRect = siteNavigationElement.getBoundingClientRect()
        top += siteNavigationRect.height
      }
    }

    return top
  }

  // MARK: - Visibility

  public calculateOffsetTop(element: HTMLElement): number {
    let offset = 0

    while (element) {
      offset += element.offsetTop
      element = element.offsetParent as HTMLElement
    }

    return offset
  }

  public updateVisibility() {
    if (this.containerElement == null) return

    if (window.pageYOffset > this.calculateOffsetTop(this.containerElement) - 16) {
      this.becomeFixed()
    } else {
      this.becomeInline()
    }
  }

  public becomeFixed() {
    if (this.containerElement == null) return
    if (this.containerElement.classList.contains('cart-form-toolbar--fixed')) return

    const containerRect = this.containerElement.getBoundingClientRect()
    this.containerElement.classList.add('cart-form-toolbar--fixed')
    this.containerElement.style.setProperty('height', `${containerRect.height}px`)
    this.updateFixedPosition()

    const siteNavigationElement = SiteNavigationController.shared.getElement()
    if (siteNavigationElement) siteNavigationElement.classList.add('site-nav--cart-form-toolbar')
  }

  public becomeInline() {
    if (this.containerElement == null) return
    if (this.containerElement.classList.contains('cart-form-toolbar--fixed') === false) return

    this.containerElement.classList.remove('cart-form-toolbar--fixed')
    this.containerElement.style.removeProperty('height')
    this.formElement?.style.removeProperty('top')

    const siteNavigationElement = SiteNavigationController.shared.getElement()
    if (siteNavigationElement) siteNavigationElement.classList.remove('site-nav--cart-form-toolbar')
  }

  private updateFixedPosition() {
    if (this.containerElement == null) return
    if (this.containerElement.classList.contains('cart-form-toolbar--fixed') === false) return
    if (this.formElement == null) return

    const top = this.calculateSiteNavigationBottom()
    this.formElement.style.setProperty('top', `${top}px`)
  }
}
