//
// Routine Controller
//

import { onChange, onLoaded, getElementFloat } from '../utilities'

export default class RoutineController {
  public static readonly shared = new RoutineController()

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(this.updateRoutinePrices.bind(this))
    onChange(
      '.routine .routine__product .routine__product__optional input[type="checkbox"]',
      this.updateRoutinePrice.bind(this)
    )
  }

  // MARK: - UI

  public updateRoutinePrices() {
    document.querySelectorAll('.routine').forEach((routineElement) => {
      this.updateRoutinePrice(routineElement)
    })
  }

  public updateRoutinePrice(referenceElement: Element) {
    const routineElement = referenceElement.closest('.routine')
    if (routineElement instanceof Element === false) return

    const submitElement = routineElement.querySelector('.routine__form button[type="submit"]')
    if (submitElement instanceof HTMLButtonElement === false) return

    const currency = routineElement.getAttribute('data-bundle-currency')
    if (typeof currency !== 'string') return

    let priceElement = submitElement.querySelector('.routine__form__price')
    if (priceElement === null) {
      const separatorElement = document.createElement('span')
      separatorElement.innerHTML = '&nbsp;&ndash;&nbsp;'
      submitElement.appendChild(separatorElement)

      priceElement = document.createElement('span')
      priceElement.classList.add('routine__form__price')
      submitElement.appendChild(priceElement)
    }

    const priceFormatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency })
    const price = this.calculateBundlePrice(routineElement)
    priceElement.textContent = priceFormatter.format(price)
  }

  // MARK: - Calculations

  public calculateBundlePrice(routineElement: Element) {
    let bundlePrice = getElementFloat(routineElement, 'data-bundle-price') || 0.0

    routineElement.querySelectorAll('.routine__product').forEach((routineProductElement) => {
      const checkboxElement = routineProductElement.querySelector('.routine__product__optional input[type="checkbox"]')
      if (checkboxElement instanceof HTMLInputElement && checkboxElement.checked === false) return

      const bundleItemPrice = getElementFloat(routineProductElement, 'data-bundle-item-price')
      if (bundleItemPrice === null) return

      bundlePrice += bundleItemPrice
    })

    return bundlePrice
  }
}
