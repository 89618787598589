//
// TrustBox Controller
//

import { addQueryToURL, httpJSON } from '../http'
import { dig, onLoaded } from '../utilities'
import symbolsFileURL from '@/images/trustpilot.svg?url'

export default class TrustBoxController {
  public static readonly controllers: TrustBoxController[] = []
  public static readonly companyDisplayName = 'Margaret Dabbs London'

  /// Returns the element whose content is managed by the controller.
  private element: HTMLElement

  /// Returns the template name used to render the content.
  private template: string

  /// Returns the SKU of the product to fetch reviews for.
  private productSKU: string | null

  /// Returns the URL of the next page of reviews.
  private nextReviewsURL: string | null

  /// Returns the element used to trigger loading more reviews.
  private nextReviewsAnchorElement: HTMLAnchorElement | null

  /// Returns the element containing the list of reviews.
  private reviewsListElement: HTMLElement | null

  /// Returns the list of translations.
  private translations: any

  // MARK: - Set Up

  public static install() {
    onLoaded(this.initializeControllers.bind(this))
  }

  public static initializeControllers() {
    const elements = Array.from(document.querySelectorAll('[data-trust-box-template]'))
    for (const element of elements) {
      if (element instanceof HTMLElement === false) continue

      const template = element.getAttribute('data-trust-box-template')
      if (typeof template !== 'string' || template.length < 1) continue

      const productSKU = element.getAttribute('data-trust-box-product-sku')
      const controller = new TrustBoxController(element, template, productSKU)
      this.controllers.push(controller)
    }
  }

  // MARK: - Object Lifecycle

  private constructor(element: HTMLElement, template: string, productSKU: string | null) {
    this.element = element
    this.template = template
    this.productSKU = productSKU
    this.nextReviewsURL = null
    this.nextReviewsAnchorElement = null
    this.reviewsListElement = null
    this.reload()
  }

  // MARK: - Formatting

  private formatDate(date: Date): string {
    const currentDate = new Date()

    if (Intl.RelativeTimeFormat) {
      const oneHourInMilliseconds = 60 * 60 * 1000
      const oneDayInMilliseconds = 24 * oneHourInMilliseconds
      const millisecondsDifference = currentDate.getTime() - date.getTime()

      if (millisecondsDifference < oneDayInMilliseconds) {
        const hoursDifference = Math.floor(millisecondsDifference / oneHourInMilliseconds)
        const formatter = new Intl.RelativeTimeFormat(document.documentElement.lang, { numeric: 'auto' })
        return formatter.format(hoursDifference * -1, 'hour')
      }
    }

    const formatter = new Intl.DateTimeFormat(document.documentElement.lang, { dateStyle: 'long' })
    return formatter.format(date)
  }

  private formatName(sourceName: string): string {
    let components = sourceName.trim().split(/[\s\.\_]+/)

    components = components.map((component) => {
      if (component === component.toLowerCase() || (component.length > 3 && component === component.toUpperCase())) {
        return component.charAt(0).toUpperCase() + component.slice(1).toLowerCase()
      } else {
        return component
      }
    })

    return components.join(' ')
  }

  // MARK: - API

  private urlForProductReviews(sku: string, perPage: number, languages: string): string {
    const url = 'https://widget.trustpilot.com/trustbox-data/57177697fdb1180308e3815f'
    const parameters = new Map<string, string>()
    parameters.set('businessUnitId', '5c17cb18da8434000155c96e')
    parameters.set('locale', document.documentElement.lang)
    parameters.set('sku', sku)
    parameters.set('reviewsPerPage', `${perPage}`)
    parameters.set('reviewLanguages', languages)

    return addQueryToURL(url, parameters)
  }

  private urlForBrandReviews(): string {
    const url = 'https://widget.trustpilot.com/trustbox-data/5419b637fa0340045cd0c936'
    const parameters = new Map<string, string>()
    parameters.set('businessUnitId', '5c17cb18da8434000155c96e')
    parameters.set('locale', document.documentElement.lang)

    return addQueryToURL(url, parameters)
  }

  private productSummaryFromResponse(response: any) {
    const verifiedSummary = response.productReviewsSummary
    const verified = {
      reviews: verifiedSummary.numberOfReviews.total,
      filteredReviews: verifiedSummary.numberOfReviews.total - verifiedSummary.numberOfReviews.oneStar,
      stars: verifiedSummary.starsAverage
    }

    const importedSummary = response.importedProductReviewsSummary
    const imported = {
      reviews: importedSummary.numberOfReviews.total,
      filteredReviews: importedSummary.numberOfReviews.total - importedSummary.numberOfReviews.oneStar,
      stars: importedSummary.starsAverage
    }

    const reviews = verified.reviews + imported.reviews
    const filteredReviews = verified.filteredReviews + imported.filteredReviews

    const average = (verified.reviews * verified.stars + imported.reviews * imported.stars) / reviews
    const stars = Math.round(average * 10 || 0) / 10

    return { verified, imported, reviews, filteredReviews, stars }
  }

  private metadataForStars(stars: number, translations: any) {
    const metadata = { rating: '', symbol: 'stars-0' }

    if (stars >= 4.8) {
      metadata.symbol = 'stars-5'
      metadata.rating = translations.fiveStars
    } else if (stars >= 4.3) {
      metadata.symbol = 'stars-4-5'
      metadata.rating = translations.fiveStars
    } else if (stars >= 3.8) {
      metadata.symbol = 'stars-4'
      metadata.rating = translations.fourStars
    } else if (stars >= 3.3) {
      metadata.symbol = 'stars-3-5'
      metadata.rating = translations.threeStars
    } else if (stars >= 2.8) {
      metadata.symbol = 'stars-3'
      metadata.rating = translations.threeStars
    } else if (stars >= 2.3) {
      metadata.symbol = 'stars-2-5'
      metadata.rating = translations.twoStars
    } else if (stars >= 1.8) {
      metadata.symbol = 'stars-2'
      metadata.rating = translations.twoStars
    } else if (stars >= 1.3) {
      metadata.symbol = 'stars-1-5'
      metadata.rating = translations.oneStar
    } else if (stars >= 1) {
      metadata.symbol = 'stars-1'
      metadata.rating = translations.oneStar
    }

    return metadata
  }

  // MARK: - Fetching

  private async reload() {
    if (this.productSKU) {
      if (this.template === 'summary') {
        await this.reloadProductSummary()
      } else if (this.template === 'reviews') {
        await this.reloadProductReviews()
      }
    } else if (this.template === 'summary') {
      await this.reloadBrandSummary()
    }
  }

  private async reloadBrandSummary() {
    this.nextReviewsURL = null

    try {
      const url = this.urlForBrandReviews()
      const payload = await httpJSON('GET', url)
      this.translations = payload.translations

      const summaryElement = document.createElement('div')
      summaryElement.classList.add('trust-box')
      summaryElement.classList.add('trust-box--summary')

      const starsTextElement = document.createElement('span')
      starsTextElement.classList.add('trust-box__stars-text')
      starsTextElement.textContent = payload.starsString
      summaryElement.appendChild(starsTextElement)

      const starsIconElement = this.buildStarsElement(payload.businessUnit.stars, this.translations)
      starsIconElement.classList.add('trust-box__stars-icon')
      summaryElement.appendChild(starsIconElement)

      const logoElement = this.buildLogoElement()
      logoElement.classList.add('trust-box__logo')
      summaryElement.appendChild(logoElement)

      this.element.innerHTML = ''
      this.element.appendChild(summaryElement)
    } catch (error) {
      console.error(error)
    }
  }

  private async reloadProductSummary() {
    if (this.productSKU == null) return

    this.nextReviewsURL = null

    try {
      const url = this.urlForProductReviews(this.productSKU, 10, 'en')
      const payload = await httpJSON('GET', url)
      this.translations = payload.translations

      const summary = this.productSummaryFromResponse(payload)
      const summaryElement = document.createElement('div')
      summaryElement.classList.add('trust-box')
      summaryElement.classList.add('trust-box--summary')

      const starsIconElement = this.buildStarsElement(summary.stars, this.translations)
      starsIconElement.classList.add('trust-box__stars-icon')
      summaryElement.appendChild(starsIconElement)

      const reviewsElement = document.createElement('span')
      reviewsElement.classList.add('trust-box__reviews')
      reviewsElement.innerHTML = this.translations.reviewCount.replace('[NOREVIEWS]', summary.filteredReviews)
      reviewsElement.textContent = reviewsElement.textContent
      summaryElement.appendChild(reviewsElement)

      this.element.innerHTML = ''
      this.element.appendChild(summaryElement)
    } catch (error) {
      console.error(error)
    }
  }

  private async reloadProductReviews() {
    if (this.productSKU == null) return

    this.nextReviewsURL = null

    try {
      const url = this.urlForProductReviews(this.productSKU, 10, 'en')
      const payload = await httpJSON('GET', url)
      this.translations = payload.translations

      if (payload.productReviews.links.nextPage) {
        this.nextReviewsURL = payload.productReviews.links.nextPage
      } else if (payload.importedProductReviews.links.nextPage) {
        this.nextReviewsURL = payload.importedProductReviews.links.nextPage
      }

      const summary = this.productSummaryFromResponse(payload)

      const productReviewsElement = document.createElement('div')
      productReviewsElement.classList.add('trust-box')
      productReviewsElement.classList.add('trust-box--product-reviews')
      productReviewsElement.setAttribute('data-trust-box-reviews', summary.filteredReviews.toString())
      productReviewsElement.setAttribute('data-trust-box-stars', summary.stars.toString())

      const summaryElement = document.createElement('div')
      summaryElement.classList.add('trust-box__summary')
      productReviewsElement.appendChild(summaryElement)

      const starsIconElement = this.buildStarsElement(summary.stars, this.translations)
      starsIconElement.classList.add('trust-box__stars-icon')
      summaryElement.appendChild(starsIconElement)

      const ratingElement = document.createElement('div')
      ratingElement.classList.add('trust-box__rating')
      summaryElement.appendChild(ratingElement)

      const starsContainerElement = document.createElement('span')
      starsContainerElement.classList.add('trust-box__stars')
      ratingElement.appendChild(starsContainerElement)

      const currentStarsElement = document.createElement('span')
      currentStarsElement.classList.add('trust-box__stars__current')
      currentStarsElement.textContent = summary.stars.toFixed(1)
      starsContainerElement.appendChild(currentStarsElement)

      const starsSeparatorElement = document.createElement('span')
      starsSeparatorElement.classList.add('trust-box__stars__separator')
      starsSeparatorElement.textContent = '/'
      starsContainerElement.appendChild(starsSeparatorElement)

      const maximumStarsElement = document.createElement('span')
      maximumStarsElement.classList.add('trust-box__stars__maximum')
      maximumStarsElement.textContent = '5'
      starsContainerElement.appendChild(maximumStarsElement)

      const ratingSeparatorElement = document.createElement('span')
      ratingSeparatorElement.classList.add('trust-box__rating__separator')
      ratingSeparatorElement.textContent = '•'
      ratingElement.appendChild(ratingSeparatorElement)

      const reviewsElement = document.createElement('span')
      reviewsElement.classList.add('trust-box__reviews')
      reviewsElement.innerHTML = this.translations.reviewCount.replace('[NOREVIEWS]', summary.filteredReviews)
      ratingElement.appendChild(reviewsElement)

      const logoElement = this.buildLogoElement()
      logoElement.classList.add('trust-box__logo')
      summaryElement.appendChild(logoElement)

      this.reviewsListElement = document.createElement('ul')
      this.reviewsListElement.classList.add('trust-box__reviews-list')
      productReviewsElement.appendChild(this.reviewsListElement)

      const noReviewsListItemElement = document.createElement('li')
      noReviewsListItemElement.classList.add('trust-box__no-reviews')
      this.reviewsListElement.appendChild(noReviewsListItemElement)

      const noReviewsElement = document.createElement('p')
      noReviewsElement.textContent = this.translations.noreviewsyet
      noReviewsListItemElement.appendChild(noReviewsElement)

      if (this.nextReviewsURL) {
        this.nextReviewsAnchorElement = document.createElement('a')
        this.nextReviewsAnchorElement.setAttribute('href', '#')
        this.nextReviewsAnchorElement.classList.add('trust-box__load-more')
        this.nextReviewsAnchorElement.textContent = this.translations.loadmore
        this.nextReviewsAnchorElement.addEventListener('click', this.loadNextReviews.bind(this))
        productReviewsElement.appendChild(this.nextReviewsAnchorElement)
      }

      this.element.innerHTML = ''
      this.element.appendChild(productReviewsElement)
      this.insertReviewListItemElements(payload)
    } catch (error) {
      console.error(error)
    }
  }

  private insertReviewListItemElements(payload: any) {
    const insertReviews = (reviews: any[], verified: boolean) => {
      if (this.reviewsListElement == null) return

      for (const review of reviews) {
        if (review.stars < 2) continue

        const reviewListItemElement = document.createElement('li')
        reviewListItemElement.classList.add('trust-box__review')
        this.reviewsListElement.appendChild(reviewListItemElement)

        const reviewHeaderElement = document.createElement('div')
        reviewHeaderElement.classList.add('trust-box__review__header')
        reviewListItemElement.appendChild(reviewHeaderElement)

        const authorElement = document.createElement('div')
        authorElement.classList.add('trust-box__review__author')
        authorElement.textContent = this.formatName(review.consumer.displayName)
        reviewHeaderElement.appendChild(authorElement)

        const createdAt = new Date(review.createdAt)
        const dateElement = document.createElement('time')
        dateElement.classList.add('trust-box__review__date')
        dateElement.setAttribute('datetime', createdAt.toISOString())
        dateElement.textContent = this.formatDate(createdAt)
        reviewHeaderElement.appendChild(dateElement)

        const starsIconElement = this.buildStarsElement(review.stars, this.translations)
        starsIconElement.classList.add('trust-box__stars-icon')
        reviewListItemElement.appendChild(starsIconElement)

        const contentElement = document.createElement('blockquote')
        contentElement.classList.add('trust-box__review__content')
        contentElement.textContent = review.content
          .trim()
          .replace(/ +/g, ' ')
          .replace(/([A-Za-z0-9])$/, '$1.')

        reviewListItemElement.appendChild(contentElement)

        const sourceElement = document.createElement('div')
        sourceElement.classList.add('trust-box__review__source')

        if (verified) {
          sourceElement.classList.add('trust-box__review__source--verified')
          sourceElement.textContent = this.translations.syndicationVerifiedLabel.replace(
            '[COMPANY]',
            TrustBoxController.companyDisplayName
          )
        } else {
          sourceElement.textContent = this.translations.importedLabel.replace(
            '[COMPANY]',
            TrustBoxController.companyDisplayName
          )
        }

        reviewListItemElement.appendChild(sourceElement)
      }
    }

    insertReviews(payload.productReviews.reviews, true)
    insertReviews(payload.importedProductReviews.productReviews, false)
  }

  private async loadNextReviews(event: Event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    if (this.nextReviewsURL == null) return

    if (this.nextReviewsAnchorElement) {
      this.nextReviewsAnchorElement.setAttribute('disabled', 'disabled')
    }

    try {
      const payload = await httpJSON('GET', this.nextReviewsURL)

      if (payload.productReviews.links.nextPage) {
        this.nextReviewsURL = payload.productReviews.links.nextPage
      } else if (payload.importedProductReviews.links.nextPage) {
        this.nextReviewsURL = payload.importedProductReviews.links.nextPage
      } else {
        this.nextReviewsURL = null

        if (this.nextReviewsAnchorElement) {
          this.nextReviewsAnchorElement.remove()
          this.nextReviewsAnchorElement = null
        }
      }

      this.insertReviewListItemElements(payload)
    } finally {
      if (this.nextReviewsAnchorElement) {
        this.nextReviewsAnchorElement.removeAttribute('disabled')
      }
    }
  }

  // MARK: - Elements

  private buildSVGElement(name: string, title: string | null, viewBox: string | null): SVGElement {
    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    svgElement.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink')

    if (typeof viewBox === 'string' && viewBox.length > 0) {
      svgElement.setAttribute('viewBox', viewBox)
    }

    if (typeof title === 'string' && title.length > 0) {
      const titleElement = document.createElement('title')
      titleElement.textContent = title
      svgElement.appendChild(titleElement)
    }

    const useElement = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElement.setAttributeNS('http://www.w3.org/1999/xlink', 'href', `${symbolsFileURL}#${name}`)
    svgElement.appendChild(useElement)

    return svgElement
  }

  private buildLogoElement(): SVGElement {
    return this.buildSVGElement('logo', 'Trustpilot', '0 0 1132.8 278.2')
  }

  private buildStarsElement(stars: number, translations: any): SVGElement {
    const metadata = this.metadataForStars(stars, translations)
    return this.buildSVGElement(metadata.symbol, metadata.rating, '0 0 512 96')
  }
}

TrustBoxController.install()
