//
// Favourites Controller
//

import {
  onClick,
  onLoaded,
  getElementBoolean,
  getElementInteger,
  getElementString,
  setElementAttribute
} from '../utilities'

export default class FavouritesController {
  public static readonly shared = new FavouritesController()

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(this.setupFavouriteButtons.bind(this))
    onClick('.favourite', this.toggleFavourited.bind(this))
  }

  // MARK: - Actions

  public setupFavouriteButtons() {
    document.querySelectorAll('.favourite').forEach((element) => {
      if (element instanceof HTMLButtonElement === false) return

      element.disabled = false
      element.addEventListener('mouseenter', () => this.showAction(element))
      element.addEventListener('mouseleave', () => this.hideAction(element))
      this.updateElementTitle(element)
    })
  }

  public async toggleFavourited(buttonElement: Element) {
    if (buttonElement instanceof HTMLButtonElement === false) return
    if (buttonElement.disabled) return

    const productId = getElementInteger(buttonElement, 'data-favourite-product-id')
    if (productId === null) return

    const modalElement = document.getElementById('modal-favourites-sign-in')
    if (modalElement instanceof HTMLElement) {
      modalElement.classList.add('modal--show')

      const inputElement = modalElement.querySelector('input[name="add-to-favourites"]') as HTMLInputElement
      if (inputElement) inputElement.value = productId.toString()

      return
    }

    const isFavourite = getElementBoolean(buttonElement, 'data-favourite')
    if (isFavourite === null) return

    let transitioned = false

    try {
      buttonElement.disabled = true
      buttonElement.classList.add('favourite--progress')
      buttonElement.classList.add('favourite--show-action')

      const isTransitioningToFavourite = isFavourite === false
      await this.setFavourite(productId, isTransitioningToFavourite)

      document.querySelectorAll(`.favourite[data-favourite-product-id="${productId}"]`).forEach((element) => {
        const productElement = element.closest('.favourites .product')
        if (productElement instanceof HTMLElement) {
          productElement.remove()
        } else {
          setElementAttribute(element, 'data-favourite', isTransitioningToFavourite)
        }
      })

      transitioned = true
    } catch (error) {
      console.error(`Failed to change favourite status of product ${productId}`, error)
    }

    buttonElement.classList.remove('favourite--progress')
    buttonElement.classList.remove('favourite--show-action')

    const animationClass = transitioned ? 'favourite--pulse' : 'favourite--wobble'
    const eventHandler = () => {
      buttonElement.removeEventListener('animationend', eventHandler)
      buttonElement.classList.remove(animationClass)
      buttonElement.disabled = false
    }

    buttonElement.addEventListener('animationend', eventHandler)
    buttonElement.classList.add(animationClass)
    this.updateElementTitle(buttonElement)
  }

  public showAction(buttonElement: HTMLButtonElement) {
    if (buttonElement.disabled) return

    buttonElement.classList.add('favourite--show-action')
  }

  public hideAction(buttonElement: HTMLButtonElement) {
    if (buttonElement.disabled) return

    buttonElement.classList.remove('favourite--show-action')
  }

  // MARK: - Networking

  private async setFavourite(productId: number, isFavourite: boolean) {
    return new Promise((resolve, reject) => {
      const action = isFavourite ? 'add_favourite_product' : 'remove_favourite_product'
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `/?wc-ajax=${action}`)
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.responseType = 'json'

      xhr.onload = function () {
        const reason = this.response && this.response.data ? this.response.data.reason : null

        if (this.status === 200) {
          resolve(reason)
        } else {
          reject(reason)
        }
      }

      xhr.onerror = function () {
        reject(this.response && this.response.data ? this.response.data.reason : null)
      }

      xhr.send(`product_id=${productId}`)
    })
  }

  // MARK: - Elements

  public updateElementTitle(buttonElement: HTMLButtonElement) {
    const isFavourite = getElementBoolean(buttonElement, 'data-favourite')
    const titleAttribute = isFavourite ? 'data-favourite-remove-title' : 'data-favourite-add-title'
    const title = getElementString(buttonElement, titleAttribute)
    setElementAttribute(buttonElement, 'title', title)
  }
}
