//
// Debounce
//

export class Debounce {
  /// Returns the delay in milliseconds to wait before performing the debounced action.
  public readonly delay: number

  /// Returns the identifier of the current timeout.
  private timeout: number | undefined

  // MARK: - Object Lifecycle

  constructor(delay: number) {
    this.delay = delay
  }

  // MARK: - Debouncing

  public perform(callback: () => void) {
    this.cancel()
    this.timeout = setTimeout(() => {
      delete this.timeout
      callback()
    }, this.delay)
  }

  public cancel() {
    if (this.timeout == null) return

    clearTimeout(this.timeout)
    delete this.timeout
  }
}
