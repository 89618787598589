//
// Form Controller
//

import { computeSize, getElementString, onChange, onLoaded, setElementAttribute } from '../utilities'
import { v4 as uuidv4 } from 'uuid'

export default class FormController {
  public static readonly shared = new FormController()

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(this.hideAllSections.bind(this))
    onChange('[data-form-toggle] input[type="checkbox"]', this.updateSectionVisibility.bind(this))
  }

  // MARK: - Element Helpers

  private referenceForElement(element: HTMLElement, createWhenMissing: boolean = false): string | null {
    let reference = getElementString(element, 'data-form-reference')
    if (reference == null && createWhenMissing) {
      reference = uuidv4()
      setElementAttribute(element, 'data-form-reference', reference)
    }

    return reference
  }

  private setFieldsDisabled(containerElement: HTMLElement, disabled: boolean) {
    containerElement.querySelectorAll('input, select, textarea').forEach((fieldElement) => {
      if (
        fieldElement instanceof HTMLInputElement ||
        fieldElement instanceof HTMLSelectElement ||
        fieldElement instanceof HTMLTextAreaElement
      ) {
        fieldElement.disabled = disabled
      }
    })
  }

  // MARK: - Visibility

  private hideAllSections() {
    setTimeout(() => {
      document.querySelectorAll('[data-form-toggle] input[type="checkbox"]').forEach((inputElement) => {
        if (inputElement instanceof HTMLInputElement === false) return

        this.updateSectionVisibility(inputElement)
      })
    }, 10)
  }

  private showSection(sectionElement: HTMLElement) {
    if (sectionElement.style.visibility === 'visible') return

    const { width, height } = computeSize(sectionElement)
    const sectionElementReference = this.referenceForElement(sectionElement, true)

    requestAnimationFrame(() => {
      this.setFieldsDisabled(sectionElement, false)

      sectionElement.style.setProperty('visibility', 'visible')
      sectionElement.style.setProperty('opacity', '1')
      sectionElement.style.setProperty('width', width)
      sectionElement.style.setProperty('height', '0')
      sectionElement.style.setProperty(
        'transition',
        'visibility 0.3s ease-in-out, opacity 0.3s ease-in-out, height 0.3s ease-in-out'
      )

      requestAnimationFrame(() => {
        const handler = (event: TransitionEvent) => {
          const element = event.target
          if (element instanceof HTMLElement === false) return

          const elementReference = this.referenceForElement(element)
          if (elementReference !== sectionElementReference) return

          element.removeEventListener('transitionend', handler)
          if (element.style.visibility !== 'visible') return

          element.style.removeProperty('width')
          element.style.removeProperty('height')
        }

        sectionElement.addEventListener('transitionend', handler)
        sectionElement.style.setProperty('height', height)
      })
    })
  }

  private hideSection(sectionElement: HTMLElement) {
    if (sectionElement.style.visibility === 'hidden') return

    const { width, height } = computeSize(sectionElement)
    const sectionElementReference = this.referenceForElement(sectionElement, true)

    requestAnimationFrame(() => {
      sectionElement.style.setProperty('visibility', 'hidden')
      sectionElement.style.setProperty('opacity', '0')
      sectionElement.style.setProperty('width', width)
      sectionElement.style.setProperty('height', height)
      sectionElement.style.setProperty(
        'transition',
        'visibility 0.3s ease-in-out, opacity 0.3s ease-in-out, height 0.3s ease-in-out'
      )

      requestAnimationFrame(() => {
        const handler = (event: TransitionEvent) => {
          const element = event.target
          if (element instanceof HTMLElement === false) return

          const elementReference = this.referenceForElement(element)
          if (elementReference !== sectionElementReference) return

          element.removeEventListener('transitionend', handler)
          if (element.style.visibility !== 'hidden') return

          element.style.removeProperty('width')

          this.setFieldsDisabled(sectionElement, true)
        }

        sectionElement.addEventListener('transitionend', handler)
        sectionElement.style.setProperty('height', '0')
      })
    })
  }

  private updateSectionVisibility(inputElement: Element) {
    if (inputElement instanceof HTMLInputElement === false) return

    const selectorReferenceElement = inputElement.closest('[data-form-toggle]')
    if (selectorReferenceElement instanceof HTMLElement === false) return

    const selector = getElementString(selectorReferenceElement, 'data-form-toggle')
    if (selector === null) return

    const sectionElement = document.querySelector(selector)
    if (sectionElement instanceof HTMLElement === false) return

    if (inputElement.checked) {
      this.showSection(sectionElement)
    } else {
      this.hideSection(sectionElement)
    }
  }
}
