//
// Hover Controller
//

import { getElementString } from '../utilities'

export default class HoverController {
  public static readonly shared = new HoverController()

  // MARK: - Object Lifecycle

  private constructor() {
    document.querySelectorAll('[data-hover-expand]').forEach((element) => {
      element.addEventListener('mouseenter', this.expand.bind(this))
      element.addEventListener('mouseleave', this.unexpand.bind(this))
    })
  }

  // MARK: - Expansion

  private expand(event: Event) {
    const containerElement = event.target
    if (containerElement instanceof HTMLElement === false) return

    const selector = getElementString(containerElement, 'data-hover-expand')
    if (selector == null) return

    containerElement.querySelectorAll(selector).forEach((element) => {
      if (element instanceof HTMLElement === false) return

      element.setAttribute('aria-expanded', 'true')
    })
  }

  private unexpand(event: Event) {
    const containerElement = event.target
    if (containerElement instanceof HTMLElement === false) return

    const selector = getElementString(containerElement, 'data-hover-expand')
    if (selector == null) return

    containerElement.querySelectorAll(selector).forEach((element) => {
      if (element instanceof HTMLElement === false) return

      element.setAttribute('aria-expanded', 'false')
    })
  }
}
