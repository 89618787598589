//
// Clinics Controller
//

/// <reference types="@types/google.maps" />

import markerGlyphStoreImageURL from '@/images/marker-glyph-store.svg?url'

import { calculateDistance, formatDistance } from '../geometry'
import {
  onClick,
  onLoaded,
  getElementBoolean,
  getElementFloat,
  getElementInteger,
  getElementList,
  getElementString,
  setElementAttribute,
  setElementSelected,
  setElementVisible
} from '../utilities'

type LocationFilter = { type: 'location'; address: string; location: google.maps.LatLng }
type RegionFilter = { type: 'region'; region: string }

export default class ClinicsController {
  public static readonly shared = new ClinicsController()

  private filter: LocationFilter | RegionFilter | null = null
  private infoWindow: google.maps.InfoWindow | null = null
  private map: google.maps.Map | null = null
  private markers: google.maps.marker.AdvancedMarkerElement[] = []
  private searchBox: google.maps.places.SearchBox | null = null

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(this.resetItemPositionAttributes.bind(this))
    onLoaded(this.mountSearchBox.bind(this))
    onClick('body.archive .clinics .clinics__search-header a', this.clearFilter.bind(this))
    onClick('body.archive .clinics .clinics__search input[type="button"]', this.filterByLocation.bind(this))
    onClick('body.archive .clinics .clinics__regions [data-clinic-region]', this.filterByRegion.bind(this))
  }

  // MARK: - Element Helpers

  private getElementCoordinates(element: Element) {
    const latitude = getElementFloat(element, 'data-clinic-latitude')
    const longitude = getElementFloat(element, 'data-clinic-longitude')
    if (latitude == null || longitude == null) return null

    return new google.maps.LatLng(latitude, longitude)
  }

  private getElementPosition(element: Element) {
    return getElementInteger(element, 'data-clinic-position')
  }

  private getElementRegion(element: Element) {
    return getElementString(element, 'data-clinic-region')
  }

  private getElementRegions(element: Element) {
    return getElementList(element, 'data-clinic-regions')
  }

  private getElementRegionActive(element: Element) {
    return getElementBoolean(element, 'data-clinic-region-active')
  }

  private setElementPosition(element: Element, position: number) {
    setElementAttribute(element, 'data-clinic-position', position)
  }

  private setElementRegionActive(element: Element, active: boolean) {
    setElementAttribute(element, 'data-clinic-region-active', active)
    setElementSelected(element, active)
  }

  // MARK: - Items

  private getItem(element: Element) {
    let distance: number | null = null
    let location: google.maps.LatLng | null = null
    let position = this.getElementPosition(element)
    let visible = true

    if (this.filter?.type === 'location') {
      location = this.getElementCoordinates(element)
      distance = location ? calculateDistance(this.filter.location, location, 'miles') : null
    } else if (this.filter?.type === 'region') {
      visible = this.getElementRegions(element).includes(this.filter.region)
    }

    return { distance, element, location, position, visible }
  }

  private getItems(referenceElement: Element) {
    const clinicsElement = referenceElement.closest('.clinics')
    if (clinicsElement instanceof Element === false) return []

    const itemsElement = clinicsElement.querySelector('.clinics__items')
    if (itemsElement instanceof Element === false) return []

    return Array.from(itemsElement.querySelectorAll(':scope > article'))
      .map((element) => this.getItem(element))
      .sort((a, b) => {
        if (a.distance != b.distance) {
          if (a.distance != null && b.distance != null) {
            return a.distance - b.distance
          } else if (a.distance != null) {
            return -1
          } else if (b.distance != null) {
            return 1
          }
        }

        const aPosition = a.position || -1
        const bPosition = b.position || -1
        return aPosition - bPosition
      })
  }

  // MARK: - Filters

  private clearFilter(referenceElement: Element) {
    this.filter = null
    this.updateUI(referenceElement)
  }

  // MARK: - UI

  private updateUI(referenceElement: Element) {
    const clinicsElement = referenceElement.closest('.clinics')
    if (clinicsElement instanceof Element === false) return

    this.updateHeader(clinicsElement)
    this.updateRegionsUI(clinicsElement)
    this.updateMapUI(clinicsElement)
    this.updateSearchUI(clinicsElement)
    this.updateItems(clinicsElement)
  }

  private updateHeader(clinicsElement: Element) {
    const headerElement = clinicsElement.querySelector('.clinics__header')
    if (headerElement instanceof Element === false) return

    setElementVisible(headerElement, this.filter?.type !== 'location')
  }

  // MARK: - Items

  private resetItemPositionAttributes() {
    const itemElements = Array.from(document.querySelectorAll('body.archive .clinics .clinics__items > article'))
    for (const [index, itemElement] of itemElements.entries()) {
      if (itemElement instanceof Element === false) continue

      this.setElementPosition(itemElement, index)
    }
  }

  private updateItems(clinicsElement: Element) {
    const itemsElement = clinicsElement.querySelector('.clinics__items')
    if (itemsElement instanceof Element === false) return

    for (const item of this.getItems(clinicsElement)) {
      setElementVisible(item.element, item.visible)

      const distanceElement = item.element.querySelector('.clinic__distance')
      if (distanceElement) distanceElement.textContent = formatDistance(item.distance, 'miles')

      itemsElement.appendChild(item.element)
    }
  }

  // MARK: - Location

  private async mountSearchBox() {
    if (this.searchBox) return

    const formElement = document.querySelector('body.archive .clinics .clinics__search__form')
    if (formElement instanceof Element === false) return

    if (window.google == null) {
      formElement.setAttribute('hidden', 'hidden')
      return
    }

    const searchElement = formElement.querySelector('input[type="search"]')
    if (searchElement instanceof HTMLInputElement === false) return

    // The `SearchBox` class is part of the `places` library.
    await google.maps.importLibrary('places')

    this.searchBox = new google.maps.places.SearchBox(searchElement)
    this.searchBox.addListener('places_changed', () => {
      if (this.searchBox == null) return

      const places = this.searchBox.getPlaces()
      if (places == null || places.length < 1) return

      const place = places[0]
      const location = place.geometry?.location
      if (location == null) return

      this.filter = { type: 'location', address: place.formatted_address || '', location }
      this.updateUI(searchElement)
    })
  }

  private filterByLocation(referenceElement: Element) {
    const clinicsElement = referenceElement.closest('.clinics')
    if (clinicsElement instanceof Element === false) return

    const searchElement = clinicsElement.querySelector('.clinics__search__form input[type="search"]')
    if (searchElement instanceof HTMLInputElement === false) return

    google.maps.event.trigger(searchElement, 'focus', {})
    google.maps.event.trigger(searchElement, 'keydown', { key: 'Enter', code: 'Enter', keyCode: 13, which: 13 })
  }

  private async updateMapUI(clinicsElement: Element) {
    const heroElement = clinicsElement.querySelector('.clinics__hero')
    if (heroElement instanceof Element === false) return

    this.setupMap(heroElement)
    this.updateMapMarkers(clinicsElement)

    if (this.filter?.type === 'location') {
      heroElement.classList.add('clinics__hero--searched')
    } else {
      heroElement.classList.remove('clinics__hero--searched')
    }
  }

  private updateSearchUI(clinicsElement: Element) {
    const searchHeaderElement = clinicsElement.querySelector('.clinics__search-header')
    if (searchHeaderElement instanceof Element === false) return

    const addressElement = searchHeaderElement.querySelector('address')
    if (addressElement instanceof Element === false) return

    if (this.filter?.type === 'location') {
      addressElement.textContent = this.filter.address
      setElementVisible(searchHeaderElement, true)
    } else {
      addressElement.textContent = ''
      setElementVisible(searchHeaderElement, false)
    }
  }

  // MARK: - Map

  private async setupMap(referenceElement: Element) {
    if (this.map) return

    const mapElement = referenceElement.querySelector('.clinics__map > .map')
    if (mapElement instanceof HTMLElement === false) return

    const mapsAPI = await google.maps.importLibrary('maps')
    const { InfoWindow, Map } = mapsAPI as google.maps.MapsLibrary

    // It's possible that `setupMap` is called multiple times while `await` is pending so we need to recheck after
    // the library has loaded otherwise we risk loading the map multiple times.
    if (this.map) return

    this.infoWindow = new InfoWindow()
    this.map = new Map(mapElement, {
      mapId: getElementString(mapElement, 'data-clinics-map-id')
    })
  }

  private resetMapMarkers() {
    for (const marker of this.markers) {
      marker.remove()
    }

    this.markers = []
  }

  private async addMapMarker(position: google.maps.LatLng, item: any) {
    const markerAPI = await google.maps.importLibrary('marker')
    const { AdvancedMarkerElement, PinElement } = markerAPI as google.maps.MarkerLibrary

    const createGlyphElement = () => {
      const imageElement = document.createElement('img')
      imageElement.src = markerGlyphStoreImageURL
      imageElement.style.maxWidth = '100%'
      imageElement.style.maxHeight = '100%'
      return imageElement
    }

    const pinElement = new PinElement({
      background: item ? '#887967' : '#047ffe',
      borderColor: item ? '#5f5549' : '#055ab2',
      glyph: item ? createGlyphElement() : null,
      glyphColor: '#ffffff',
      scale: 1.25
    })

    const markerElement = new AdvancedMarkerElement({
      map: this.map,
      position,
      content: pinElement.element
    })

    if (item) {
      markerElement.addListener('click', () => {
        const infoWindow = this.infoWindow
        if (infoWindow == null) return

        infoWindow.close()

        const contentElement = item.element.querySelector('.clinic__content')
        if (contentElement instanceof Element === false) return

        infoWindow.setContent(contentElement.innerHTML)
        infoWindow.open({ anchor: markerElement, map: markerElement.map })
      })
    }

    this.markers.push(markerElement)
  }

  private async updateMapMarkers(clinicsElement: Element) {
    this.resetMapMarkers()
    if (this.filter?.type !== 'location') return

    const bounds = new google.maps.LatLngBounds()
    bounds.extend(this.filter.location)

    await this.addMapMarker(this.filter.location, null)

    for (const [index, item] of this.getItems(clinicsElement).entries()) {
      if (item.location == null) continue
      if (index === 0) bounds.extend(item.location)

      await this.addMapMarker(item.location, item)
    }

    this.map?.fitBounds(bounds)
  }

  // MARK: - Regions

  private filterByRegion(referenceElement: Element) {
    const region = this.getElementRegion(referenceElement)
    if (!region) return

    this.filter = this.getElementRegionActive(referenceElement) ? null : { type: 'region', region }
    this.updateUI(referenceElement)
  }

  private updateRegionsUI(clinicsElement: Element) {
    const regionsElement = clinicsElement.querySelector('.clinics__regions')
    if (regionsElement instanceof Element === false) return

    setElementVisible(regionsElement, this.filter?.type !== 'location')

    const regionElements = Array.from(regionsElement.querySelectorAll('[data-clinic-region]'))
    for (const regionElement of regionElements) {
      if (regionElement instanceof Element === false) continue

      this.setElementRegionActive(
        regionElement,
        this.filter?.type === 'region' && this.filter.region === this.getElementRegion(regionElement)
      )
    }
  }
}
