//
// Region Controller
//

import { onSubmit } from '../utilities'

export default class RegionController {
  public static readonly shared = new RegionController()

  // MARK: - Object Lifecycle

  private constructor() {
    onSubmit('.header form.header__regions__links', this.hideBanner.bind(this))
  }

  // MARK: - Actions

  public hideBanner(formElement: Element) {
    if (formElement instanceof HTMLFormElement === false) return

    const rowElement = formElement.closest('.header__row')
    if (rowElement) rowElement.remove()

    const date = new Date()
    date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 90)
    document.cookie = 'mdl_region_banner_status=hidden; expires=' + date.toUTCString() + '; path=/'
  }
}
