//
// Klaviyo Support
//

interface Window {
  _klOnsite: any
}

document.addEventListener('click', (event) => {
  const element = event.target
  if (element instanceof HTMLElement == false) return

  const anchorElement = element.closest('a')
  if (anchorElement == null) return

  const href = anchorElement.getAttribute('href')
  if (typeof href !== 'string' || href.startsWith('#klaviyo-form-') == false) return

  const formId = href.replace('#klaviyo-form-', '')
  if (formId.length < 1) return

  event.stopImmediatePropagation()
  event.preventDefault()
  window._klOnsite = window._klOnsite || []
  window._klOnsite.push(['openForm', formId])
})
