//
// Accordion Controller
//

import { computeSize, onClick } from '../utilities'

export default class AccordionController {
  public static readonly shared = new AccordionController()

  // MARK: - Object Lifecycle

  private constructor() {
    onClick('.accordion .accordion__item .accordion__item__toggle', this.toggleItem.bind(this))
    onClick('[data-accordion-open]', this.focusItem.bind(this))
  }

  // MARK: - Visibility

  public isItemOpen(itemElement: HTMLElement): boolean {
    return itemElement.classList.contains('accordion__item--open')
  }

  public openItem(itemElement: HTMLElement) {
    if (itemElement.classList.contains('accordion__item--open')) return

    const contentElement = itemElement.querySelector('.accordion__item__content')
    if (contentElement instanceof HTMLElement === false) return

    const { width, height } = computeSize(contentElement)

    requestAnimationFrame(() => {
      contentElement.style.setProperty('width', width)
      contentElement.style.setProperty('height', '0')
      contentElement.style.setProperty('overflow', 'hidden')
      itemElement.classList.add('accordion__item--open')

      requestAnimationFrame(() => {
        const handler = (event: TransitionEvent) => {
          const element = event.target
          if (element instanceof HTMLElement === false) return
          if (element.classList.contains('accordion__item__content') === false) return

          contentElement.style.removeProperty('width')
          contentElement.style.removeProperty('height')
          contentElement.style.removeProperty('overflow')
          contentElement.removeEventListener('transitionend', handler)
        }

        contentElement.addEventListener('transitionend', handler)
        contentElement.style.setProperty('height', height)
      })
    })
  }

  public closeItem(itemElement: HTMLElement) {
    if (itemElement.classList.contains('accordion__item--open') === false) return

    const contentElement = itemElement.querySelector('.accordion__item__content')
    if (contentElement instanceof HTMLElement === false) return

    const { width, height } = computeSize(contentElement)

    requestAnimationFrame(() => {
      contentElement.style.setProperty('width', width)
      contentElement.style.setProperty('height', height)
      contentElement.style.setProperty('overflow', 'hidden')
      itemElement.classList.remove('accordion__item--open')

      requestAnimationFrame(() => {
        const handler = (event: TransitionEvent) => {
          const element = event.target
          if (element instanceof HTMLElement === false) return
          if (element.classList.contains('accordion__item__content') === false) return

          contentElement.style.removeProperty('width')
          contentElement.style.removeProperty('height')
          contentElement.style.removeProperty('overflow')
          contentElement.removeEventListener('transitionend', handler)
        }

        contentElement.addEventListener('transitionend', handler)
        contentElement.style.setProperty('height', '0')
      })
    })
  }

  public toggleItem(referenceElement: Element) {
    const itemElement = referenceElement.closest('.accordion__item')
    if (itemElement instanceof HTMLElement === false) return

    if (this.isItemOpen(itemElement)) {
      this.closeItem(itemElement)
    } else {
      this.openItem(itemElement)
    }
  }

  public focusItem(referenceElement: Element) {
    const selector = referenceElement.getAttribute('data-accordion-open')
    if (selector === null) return

    const targetElement = document.querySelector(selector)
    if (targetElement instanceof HTMLElement === false) return

    const itemElement = targetElement.closest('.accordion__item')
    if (itemElement instanceof HTMLElement === false) return

    this.openItem(itemElement)
    itemElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
