//
// Gallery Controller
//

import Splide from '@splidejs/splide'
import { onLoaded } from '../utilities'

export default class GalleryController {
  public static readonly controllers = new Map<String, GalleryController>()

  public readonly identifier: string

  public readonly mainElement: HTMLElement
  public readonly mainSplide: Splide

  public readonly thumbnailsElement: HTMLElement | null
  public readonly thumbnailsSplide: Splide | null

  // MARK: - Set Up

  public static install() {
    onLoaded(this.initializeControllers.bind(this))
  }

  public static initializeControllers() {
    const mainElements = Array.from(document.querySelectorAll('[data-gallery]'))
    for (const mainElement of mainElements) {
      if (mainElement instanceof HTMLElement === false) continue

      const identifier = mainElement.getAttribute('data-gallery')
      if (typeof identifier !== 'string' || identifier.length < 1) continue
      if (this.controllers.has(identifier)) continue

      let thumbnailsElement = document.querySelector(`[data-gallery-thumbnails="${identifier}"]`)
      if (thumbnailsElement instanceof HTMLElement === false) thumbnailsElement = null

      const controller = new GalleryController(identifier, mainElement, thumbnailsElement)
      this.controllers.set(identifier, controller)
    }
  }

  // MARK: - Object Lifecycle

  public constructor(identifier: string, mainElement: HTMLElement, thumbnailsElement: HTMLElement | null) {
    this.identifier = identifier

    this.mainElement = mainElement
    this.mainSplide = new Splide(mainElement, {
      easing: 'ease-in-out',
      interval: 350,
      arrows: false,
      pagination: false,
      rewind: true,
      perPage: 1,
      perMove: 1,
      breakpoints: {
        991: {
          pagination: true
        }
      }
    })

    if (thumbnailsElement) {
      this.thumbnailsElement = thumbnailsElement
      this.thumbnailsSplide = new Splide(thumbnailsElement, {
        easing: 'ease-in-out',
        interval: 350,
        arrows: false,
        pagination: false,
        rewind: true,
        isNavigation: true,
        perPage: thumbnailsElement.classList.contains('splide--products') ? 6 : 4,
        perMove: 1,
        gap: 8
      })

      this.thumbnailsSplide.on('overflow', (isOverflow) => {
        const splide = this.thumbnailsSplide
        if (splide == null) return

        splide.options = {
          focus: isOverflow ? 'center' : 0
        }
      })

      this.mainSplide.sync(this.thumbnailsSplide)
      this.mainSplide.mount()
      this.thumbnailsSplide.mount()
    } else {
      this.mainSplide.mount()
      this.thumbnailsElement = null
      this.thumbnailsSplide = null
    }
  }
}

GalleryController.install()
