//
// Splide Controller
//

import Splide from '@splidejs/splide'
import { onLoaded } from '../utilities'

export default class SplideController {
  public static readonly controllers: SplideController[] = []

  public readonly element: Element
  public readonly splide: Splide

  // MARK: - Set Up

  public static install() {
    onLoaded(this.initializeControllers.bind(this))
  }

  public static initializeControllers() {
    const elements = Array.from(document.querySelectorAll('[data-splide]'))
    for (const element of elements) {
      if (element instanceof HTMLElement === false) continue
      if (element.classList.contains('is-initialized')) continue

      const controller = new SplideController(element)
      this.controllers.push(controller)
    }
  }

  // MARK: - Object Lifecycle

  public constructor(element: HTMLElement) {
    this.element = element
    this.splide = new Splide(element)
    this.splide.mount()
    this.splide.on('resized', this.updateAutoplay.bind(this))
    this.updateAutoplay()
  }

  // MARK: - Event Handlers

  public updateAutoplay() {
    const { Autoplay } = this.splide.Components

    if (this.splide.options.autoplay && Autoplay.isPaused()) {
      Autoplay.play()
    } else if (this.splide.options.autoplay == false && Autoplay.isPaused() == false) {
      Autoplay.pause()
    }
  }
}

SplideController.install()
