//
// HTTP
//

export function buildQuery(parameters: Map<string, string>): string {
  let first = true
  let query = ''

  parameters.forEach((value, key) => {
    if (!first) query += '&'

    first = false
    query += encodeURIComponent(key)
    query += '='
    query += encodeURIComponent(value)
  })

  return query
}

export function addQueryToURL(url: string, parameters: Map<string, string>): string {
  const query = buildQuery(parameters)
  return `${url}?${query}`
}

export function http(
  method: string,
  url: string,
  parameters: Map<string, string> | null = null,
  headers: Map<string, string> | null = null,
  body: Document | XMLHttpRequestBodyInit | null | undefined = null
): Promise<XMLHttpRequest> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {
      if (this.readyState !== 4) return

      if (this.status >= 200 && this.status < 400) {
        resolve(this)
      } else {
        reject(this)
      }
    }

    const requestURL = parameters ? addQueryToURL(url, parameters) : url
    xhr.open(method, requestURL, true)

    if (headers) {
      headers.forEach((value, key) => {
        xhr.setRequestHeader(key, value)
      })
    }

    xhr.send(body)
  })
}

export function httpJSON(
  method: string,
  url: string,
  parameters: Map<string, string> | null = null,
  headers: Map<string, string> | null = null,
  body: any = null
): Promise<any> {
  let requestHeaders = headers
  let requestBody = body

  if (requestHeaders == null) {
    requestHeaders = new Map<string, string>()
  }

  requestHeaders.set('Accept', 'application/json')

  if (requestBody) {
    requestHeaders.set('Content-Type', 'application/json')
    requestBody = JSON.stringify(body)
  }

  return http(method, url, parameters, requestHeaders, requestBody)
    .then((xhr) => JSON.parse(xhr.responseText))
    .catch((result) => {
      if (result instanceof XMLHttpRequest) {
        const contentType = result.getResponseHeader('Content-Type')
        let cause = 'Unknown'

        if (contentType === 'text/plain') {
          cause = result.responseText
        } else if (contentType === 'application/json') {
          cause = JSON.parse(result.responseText)
        }

        return new Error(`HTTP ${result.status} ${result.responseText}`, { cause })
      }

      return result
    })
}
