//
// Modal Controller
//

import { getElementBoolean, getElementString, onClick, onLoaded } from '../utilities'

export default class ModalController {
  public static readonly shared = new ModalController()

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(this.repositionModals.bind(this))
    onClick('[data-modal-toggle]', this.toggleModal.bind(this))

    document.addEventListener('click', (event) => {
      const element = event.target
      if (element instanceof HTMLElement === false) return
      if (element.classList.contains('modal') === false) return

      event.preventDefault()
      event.stopImmediatePropagation()
      this.hideModal(element)
      this.reloadFrameElements(element)
    })
  }

  // MARK: - Setup

  public repositionModals() {
    document.querySelectorAll('.modal[data-modal-reposition]').forEach((modalElement) => {
      const position = modalElement.getAttribute('data-modal-reposition')
      if (position !== 'footer') return

      document.body.appendChild(modalElement)
    })
  }

  // MARK: - Visibility

  public isModalVisibile(element: Element): boolean {
    return element.classList.contains('modal--show')
  }

  public showModal(element: Element) {
    if (element.classList.contains('modal--show')) return

    element.classList.add('modal--show')
  }

  public hideModal(element: Element) {
    if (element.classList.contains('modal--show') === false) return

    element.classList.remove('modal--show')
  }

  public toggleModal(referenceElement: Element) {
    const modalElement = this.findRelatedModalElement(referenceElement)
    if (modalElement instanceof Element === false) return

    if (this.isModalVisibile(modalElement)) {
      this.hideModal(modalElement)
      this.reloadFrameElements(modalElement)
    } else {
      this.showModal(modalElement)

      if (getElementBoolean(referenceElement, 'data-modal-autoplay')) {
        this.playFrameElement(modalElement)
      }
    }
  }

  // MARK: - Frames

  public playFrameElement(element: Element) {
    const frameElement = element.querySelector('iframe')
    if (frameElement instanceof HTMLIFrameElement === false) return

    const frameWindow = frameElement.contentWindow
    if (frameWindow == null) return

    const url = getElementString(frameElement, 'src')
    if (url == null) return

    if (/^https?:\/\/player\.vimeo\.com\//.test(url)) {
      frameWindow.postMessage({ method: 'play' }, '*')
    }
  }

  public reloadFrameElements(element: Element) {
    element.querySelectorAll('iframe').forEach((frameElement) => {
      const url = frameElement.getAttribute('src')
      if (typeof url !== 'string' || url.length < 1) return

      frameElement.setAttribute('src', url)
    })
  }

  // MARK: - Elements

  public findRelatedModalElement(element: Element): Element | null {
    if (element.classList.contains('modal')) return element

    const toggleElement = element.closest('[data-modal-toggle]')
    if (toggleElement instanceof Element === false) return null

    const modalSelector = toggleElement.getAttribute('data-modal-toggle')
    if (typeof modalSelector !== 'string' || modalSelector.length < 0) return null

    const modalElement =
      modalSelector === 'self' ? toggleElement.closest('.modal') : document.querySelector(modalSelector)
    if (modalElement instanceof Element === false) return null

    return modalElement
  }
}
